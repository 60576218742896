import React from 'react'
import { Link } from 'gatsby'
// import logo from '../../static/images/Logo.png';
import facebookIcon from "../../static/images/facebook-icon.png"
import linkdinIcon from "../../static/images/linkdin-icon.png";
import twitterIcon from "../../static/images/twitter-icon.png";
import 'bootstrap/dist/js/bootstrap'
import ReactGA from 'react-ga'
import { FacebookIcon, TwitterIcon, GooglePlusIcon, LinkedinIcon } from 'react-share';

class Footer extends React.Component {

    goggleAnalyticsLogger(category) {
        const baseCategory = 'blog/';
        ReactGA.event({
            category: baseCategory + category,
            action: 'link'
        })
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <Link to="/">
                        <img className="footer-logo" src="/images/logo.png" alt="" />
                    </Link>
                    <div className="row">
                        <div className="col-6">
                            <div className="footer-nav">
                                <h4>Over</h4>
                                <ul>
                                    <li><Link to="/overons">Over ons</Link></li>
                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                    <li><Link to="/diensten">Diensten</Link></li>
                                    <li><Link to="/huuronsin">Huur ons in</Link></li>
                                    <li><Link to="/neemcontactop">Neem contact op</Link></li>
                                    <li><a target="_blank" href="/privacy_statement_27-08-2020.pdf">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <h4>Ons kantoor</h4>
                            <p>IJzersterk Tekenwerk is een handelsnaam van</p>
                            <p>IJzersterk Laserwerk B.V.</p><br/>
                            <p>Schaijksestraat 2</p>
                            <p>5375 KC, Reek (Noord Brabant) <a href="tel:0885251711">0885251711</a></p>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>Copyright &copy; 2020 Ijzersterk Tekenwerk. All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer

import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/js/bootstrap';

class Header extends React.Component {
  componentDidMount() {
    // Call initialMenuJs() after megaMenu.js loaded
    const checkHeaderComponentInit = setInterval(function () {
      const isMegaMenuInit = document.querySelector('.globalNav')

      if (isMegaMenuInit && typeof initialMenuJs !== 'undefined') {
        // call megaMenu.js function
        initialMenuJs();

        // clear Interval
        clearInterval(checkHeaderComponentInit)
      }
    }, 100)
  }

  render() {
    return (
      <header className="trt-header">
        <div className="globalNav">
          <div className="container position-relative">
            <Link className="trt-logo" to="/">
              <img src="/images/logo.png" height="70" alt="" />
            </Link>
            <ul className="navRoot">
              <li className="navSection primary">
                <Link className="rootLink" to="/overons">Over ons</Link>
                <Link className="rootLink" to="/portfolio">Portfolio</Link>
                <Link className="rootLink" to="/diensten">Diensten</Link>
                <Link className="rootLink" to="/huuronsin">Huur ons in</Link>
                <Link className="rootLink" to="/industrieen">Industrieën</Link>
                <Link className="rootLink" to="/neemcontactop">
                  <button type="button" className="btn btn-trt-primary">Neem contact op</button>
                </Link>
              </li>
              <li className="navSection mobile">
                <a className="rootLink item-mobileMenu">
                  <h2>Menu</h2>
                </a>
                <div className="popup">
                  <div className="popupContainer">
                    <a className="popupCloseButton">Close</a>
                    <div className="mobileSecondaryNav">
                      <ul>
                        <li>
                          <Link className="linkContainer item-about" to="/overons" >
                            <p className="linkTitle linkIcon">Over ons</p>
                          </Link>
                        </li>
                        <li>
                          <Link className="linkContainer item-team" to="/portfolio" >
                            <p className="linkTitle linkIcon">Portfolio</p>
                          </Link>
                        </li>
                        <li>
                          <Link className="linkContainer item-team" to="/diensten" >
                            <p className="linkTitle linkIcon">Diensten</p>
                          </Link>
                        </li>
                        <li>
                          <Link className="linkContainer item-team" to="/huuronsin" >
                            <p className="linkTitle linkIcon">Huur ons in</p>
                          </Link>
                        </li>
                        <li>
                          <Link className="linkContainer item-team" to="/industrieen" >
                            <p className="linkTitle linkIcon">Industrieën</p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <Link className="mobileSignIn" to="/neemcontactop" >Neem contact op</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="dropdownRoot">
            <div className="dropdownBackground">
              <div className="alternateBackground"></div>
            </div>
            <div className="dropdownArrow"></div>
            <div className="dropdownContainer">
              <div className="dropdownSection left" data-dropdown="services">
                <div className="dropdownContent">
                  <div className="linkGroup documentationGroup">
                    <div className="documentationArticles">
                      <ul>
                        <li>
                          <Link to="/" >AutoCAD 2d</Link>
                        </li>
                        <li>
                          <Link to="/" >Inventor</Link>
                        </li>
                        <li>
                          <Link to="/" >Solidworks</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="linkGroup linkList indsubGroup">
                    <li>
                      <Link className="linkContainer item-workswith" to="/">
                        <p className="linkTitle linkIcon text-center">Work with Thirdrocktechkno</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdownSection active" data-dropdown="company">
                <div className="dropdownContent">
                  <div className="linkGroup documentationGroup">
                    <Link className="linkContainer withIcon item-service" to="/huuronsin">
                      <p className="linkTitle linkIcon">
                        <svg width="17" height="17" viewBox="0 0 15 13.562">
                          <path fill="#3d7ef1" fillRule="evenodd" d="M300.584,478.673A1.584,1.584,0,1,0,299,480.257,1.586,1.586,0,0,0,300.584,478.673Zm0.156,2.351h0l-0.452-.139a0.141,0.141,0,0,0-.173.086l-0.951,2.608a0.171,0.171,0,0,1-.322,0l-0.951-2.608a0.14,0.14,0,0,0-.132-0.092c-0.013,0-.493.144-0.493,0.144a1.4,1.4,0,0,0-.963,1.333v2.759a0.137,0.137,0,0,0,.023.077l0.992,1.51v3.819a0.14,0.14,0,0,0,.14.14h3.09a0.14,0.14,0,0,0,.14-0.14V486.7l0.992-1.51a0.137,0.137,0,0,0,.023-0.077v-2.766A1.394,1.394,0,0,0,300.74,481.024Zm-1.369-.055a0.21,0.21,0,0,0-.154-0.064h-0.434a0.214,0.214,0,0,0-.155.064,0.2,0.2,0,0,0-.026.237l0.232,0.349-0.109.915,0.214,0.568a0.065,0.065,0,0,0,.122,0l0.214-.568-0.109-.915,0.232-.349A0.2,0.2,0,0,0,299.371,480.969Z" transform="translate(-291.5 -477.094)"></path>
                          <path fill="#87bbfc" fillRule="evenodd" d="M303.18,480.4a1.228,1.228,0,1,0,1.228-1.228A1.23,1.23,0,0,0,303.18,480.4Zm2.485,1.635-0.313-.1a0.368,0.368,0,0,0-.327.057l-0.617.462L303.791,482a0.37,0.37,0,0,0-.328-0.057l-0.312.1a1.215,1.215,0,0,0-.832,1.155v1.856a0.37,0.37,0,0,0,.06.2L303,486.2v2.488a0.367,0.367,0,0,0,.367.367h2.078a0.367,0.367,0,0,0,.367-0.367V486.2l0.623-.947a0.37,0.37,0,0,0,.06-0.2v-1.856A1.217,1.217,0,0,0,305.665,482.039Zm-12.073-.407a1.228,1.228,0,1,0-1.228-1.228A1.23,1.23,0,0,0,293.592,481.632Zm1.257,0.407-0.312-.1a0.37,0.37,0,0,0-.328.057l-0.617.462L292.975,482a0.368,0.368,0,0,0-.327-0.057l-0.313.1a1.215,1.215,0,0,0-.832,1.155v1.856a0.37,0.37,0,0,0,.06.2l0.623,0.947v2.488a0.367,0.367,0,0,0,.367.367h2.078a0.367,0.367,0,0,0,.367-0.367V486.2l0.623-.947a0.37,0.37,0,0,0,.06-0.2v-1.856A1.215,1.215,0,0,0,294.849,482.039Z" transform="translate(-291.5 -477.094)"></path>
                        </svg>Hire US
                      </p>
                      <span className="linkSub">Integrate Thirdrocktechkno’ solutions and applications.</span>
                    </Link>
                    <div className="documentationArticles">
                      <ul>
                        <li>
                          <Link to="/hire-voice-developers/"><h4>HIRE VOICE DEVELOPER</h4></Link>
                        </li>
                        <li>
                          <Link to="/hire-alexa-skill-developer/" >Hire Alexa Skill Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-google-actions-developer/" >Hire Google Actions Developer</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/hire-web-developer/"><h4>HIRE WEB DEVELOPER</h4></Link>
                        </li>
                        <li>
                          <Link to="/hire-angular-developer/" >Hire Angular Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-node-developer/" >Hire NodeJS Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-reactjs-developer/" >Hire ReactJS Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-vuejs-developer/" >Hire VueJS Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-python-developer/" >Hire Python Developer</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/hire-mobile-app-developers/"><h4>HIRE MOBILE DEVELOPER</h4></Link>
                        </li>
                        <li>
                          <Link to="/hire-ionic-developer/" >Hire Ionic Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-react-developer/" >Hire React Native Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-android-developer/" >Hire Android Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-ios-developer/" >Hire IOS Developer</Link>
                        </li>
                        <li>
                          <Link to="/hire-ar-developer/" >Hire AR App Developer</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="linkGroup linkList indsubGroup">
                    <li>
                      <Link className="linkContainer item-workswith" to="/">
                        <p className="linkTitle linkIcon text-center">Work with Thirdrocktechkno</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
